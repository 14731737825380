import { Link, navigate } from "gatsby";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import {
  RealmAppProvider,
  useRealmApp,
  APP_ID,
  OnboardingRedirector,
} from "../../../components/realm_app";
import { validateEmail, validatePassword, getUrlParam } from "../../../utils";
import Navbar from "../../../components/onboarding_navbar";
import { Footer } from "../../../components/footer";
import { Form } from '../../../components/Form/Form';
import "../../../index.css";

const SignupPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [loading, setLoading] = useState("");
  const realmApp = useRealmApp();

  //TODO: Think of better place to store this data
  const formContent = [
    {'heading': 'Email:', 'type': 'email', 'value': email, 'func': setEmail},
    {'heading': 'Password:', 'type': 'password', 'value': password, 'func': setPassword},
    {'heading': 'Retype password:', 'type': 'password', 'value': passwordConfirmation, 'func': setPasswordConfirmation}
  ];

  async function handleSignUp(event) {
    event.preventDefault();
    setLoading("registering...");
    var invite_code = getUrlParam("invite_code");

    var validated = true;
    try {
      validateEmail(email);
      validatePassword(password);
    } catch (err) {
      console.log(err);
      alert(err);
      validated = false;
    }
    if (validated && password != passwordConfirmation) {
      alert("Passwords do not match, please try again.");
      validated = false;
    } else if (validated && invite_code == "") {
      alert(
        "Invalid Invite URL. Please make sure to use the exact signup " +
          "URL provided by your facilitator/program manager."
      );
      validated = false;
    }

    if (validated) {
      try {
        // Register the user and, if successful, send them to email confirmation page.
        console.log(`invite code is ${invite_code}`);
        console.log(`registering ${email}`);
        await realmApp.Register(email, password);
        console.log(`logging in ${email}`);
        await realmApp.LogIn(email, password);
        console.log(`refreshing data`);
        await realmApp.appRefreshCustomData();
        console.log(`refresh data done`);
        navigate("/member/signup/join_circle/?invite_code=" + invite_code);
      } catch (err) {
        if (err.errorCode == "AccountNameInUse") {
          alert(`Email already in use.`);
        } else {
          console.log(JSON.stringify(err));
          alert(`error: ${err.error}`);
        }
      }
    }

    setLoading("");
  }

  return (
    <>
      <main className='page'>
        <Navbar />
        <div className="page-content-center">
          <h3>Create an Account</h3>
        </div>
        <div className='page-content-center'>
          <div className='page-content page-content-small'>
            <Form onSubmitFunc={handleSignUp} formContent={formContent} />
          </div>
        </div>
        {loading}
        <h6 className='page-content-center'>
          Already have an account? <Link className='link' to='/member/login/'>Log In</Link>
        </h6>
      </main>
      <Footer />
    </>
  );
};

export default ({ location }) => {
  return (
    <RealmAppProvider appId={APP_ID}>
      <OnboardingRedirector location={location}>
        <SignupPage />
      </OnboardingRedirector>
    </RealmAppProvider>
  );
};
